import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import FilterLocation from '../pages/FilterLocation';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import InfoIconWithTooltip from './ToolTip';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`incident-tabpanel-${index}`}
            aria-labelledby={`incident-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `incident-tab-${index}`,
        'aria-controls': `incident-tabpanel-${index}`,
    };
}
const renderLocation = (row) => {
    const details = row.applicationDetails || {};  // Fallback to empty object if applicationDetails is undefined
    const locationOne = details.locationOne ? details.locationOne.name + ' > ' : '';
    const locationTwo = details.locationTwo ? details.locationTwo.name + ' > ' : '';
    const locationThree = details.locationThree ? details.locationThree.name + ' > ' : '';
    const locationFour = details.locationFour ? details.locationFour.name : '';

    const fullLocationChain = locationOne + locationTwo + locationThree + locationFour;

    return fullLocationChain || ''; // Return the full chain or an empty string if none
}
const ReportModal = ({ type, actionData, formData, setFormData }) => {

    const [value, setValue] = useState(0);
    const [topLevelValue, setTopLevelValue] = useState(0);
    const [maxDate, setMaxDate] = useState(new Date());
    const currentDate = new Date();
    const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const [monthYear, setMonthYear] = useState(firstDayOfPreviousMonth);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTopLevelChange = (event, newValue) => {
        setTopLevelValue(newValue);
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();

        // Set the date to the first day of the current month
        const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        // Subtract one day to get the last day of the previous month
        const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
        lastDayOfPreviousMonth.setDate(0);

        // Set maxDate to the last day of the previous month
        setMaxDate(lastDayOfPreviousMonth);
    }, []);

    const [location, setLocation] = useState({
        locationOneId: '', locationTwoId: '', locationThreeId: '', locationFourId: ''
    })

    useEffect(() => {
        if (actionData.applicationDetails) {
            const details = actionData.applicationDetails || {};  // Fallback to empty object if applicationDetails is undefined
            const locationOne = details.locationOne ? details.locationOne.id : '';
            const locationTwo = details.locationTwo ? details.locationTwo.id : '';
            const locationThree = details.locationThree ? details.locationThree.id : '';
            const locationFour = details.locationFour ? details.locationFour.id : '';
            setFormData(prevFormData => ({
                ...prevFormData,
                locationOneId: locationOne,
                locationTwoId: locationTwo,
                locationThreeId: locationThree,
                locationFourId: locationFour
            }))
            setLocation({ locationOneId: locationOne, locationTwoId: locationTwo, locationThreeId: locationThree, locationFourId: locationFour })
        }

    }, [actionData])
    const handleFilter = (locationOneId, locationTwoId, locationThreeId, locationFourId) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            locationOneId: locationOneId,
            locationTwoId: locationTwoId,
            locationThreeId: locationThreeId,
            locationFourId: locationFourId
        }))
        setLocation({ locationOneId: locationOneId, locationTwoId: locationTwoId, locationThreeId: locationThreeId, locationFourId: locationFourId })
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        if (id === 'dateInput') {
            // For the date input, convert and update the state as 'DD/MM/YYYY'
            const formattedDate = moment(value).format('DD/MM/YYYY');
            setFormData(prevFormData => ({
                ...prevFormData,
                date: formattedDate
            }));
        } else {
            // For other inputs, update the state directly
            setFormData(prevFormData => ({
                ...prevFormData,
                [id]: value
            }));
        }
    };

    const handleDateChange = (newDate) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            date: moment(newDate).format('DD/MM/YYYY')
        }));
    };

    // Initialize with an empty string or current month-year

    const handleMonthYearChange = (date) => {
        // Convert YYYY-MM to MM/YY

        setFormData(prevFormData => ({
            ...prevFormData,
            yearAndMonth: format(date, 'MMM yyyy')
        }))
        setMonthYear(date);
    };

    const handleWheel = (event) => {

        event.target.blur();  // Prevents scrolling changing the number
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>

                {type === 'daily' && <form>

                    <FilterLocation disableAll={true} handleFilter={handleFilter} />

                    <div className="mb-3">
                        <label htmlFor="dateInput" className="form-label">Date *</label>
                        <input
                            type="date"
                            className="form-control"
                            id="dateInput"
                            value={moment(formData.date, 'DD/MM/YYYY').isValid() ? moment(formData.date, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''}
                            onChange={handleInputChange}

                        />
                    </div>



                </form>}

                {type === 'monthly' && <form>





                    {actionData.applicationDetails && renderLocation(actionData)}
                    {!actionData.applicationDetails && <FilterLocation disableAll={true} handleFilter={handleFilter} />}

                    <div className="mb-3">
                        <div>
                            <DatePicker
                                selected={monthYear}
                                onChange={handleMonthYearChange}
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                maxDate={maxDate}
                                className='form-control'
                                disabled
                            />
                            <p>Selected Month and Year: {format(monthYear, 'MMM yyyy')}</p>
                        </div>
                    </div>

                    {/* Below are the safety related fields */}


                    <div className="mb-3">
                        <label htmlFor="sttEmployeesInput" className="form-label">Number of STT GDC Employees on-site * <InfoIconWithTooltip id="info-sttEmployeesInput" data="It takes TOTAL NUMBER per month.
(e.g., employees per day per month x number of working days
for the month)
(e.g., 4 employees x 22 working days = 88 number of STT GDC
Employees on site)" /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="numberOfEmployees"
                            value={formData.numberOfEmployees}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="dailyHoursInput" className="form-label">Total number of hours worked of STT GDC employee * <InfoIconWithTooltip id="info-dailyHoursInput" data={`It takes TOTAL NUMBER of hours worked for the month.
(e.g., employees per day per month x hours worked per day x
number of working days for the month)
(e.g., 4 employees x 8 hours x 22 working days = 704 hours
worked for the month.
Note: If the STT GDC employees are having different working hours (e.g.
shift work, etc), take average number of working hours per day into the
calculation.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="dailyHoursOfEmployee"
                            value={formData.dailyHoursOfEmployee}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="workingDaysOfEmployee" className="form-label">Number of working days for STT GDC employee * <InfoIconWithTooltip id="info-workingDaysOfEmployee" data={`It takes number of working days for STT GDC Employees for
that month. (e.g., 22 days)
Note: If STT GDC employees are having different working days (e.g.
working on shift arrangement), take average number of working days in a
month.`} /> </label>
                        <input
                            type="number"
                            className="form-control"
                            id="workingDaysOfEmployee"
                            value={formData.workingDaysOfEmployee}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>



                    <div className="mb-3">
                        <label htmlFor="contractorEmployeesInput" className="form-label">Number of Contractor Employees on-site (incl. GC/Main Contractor, Sub-contractors, etc.) * <InfoIconWithTooltip id="info-contractorEmployeesInput" data={`It takes TOTAL NUMBER per month.
(e.g., employees per day per month x number of working days
for the month)
(e.g., 120 contractor employees x 30 working days = 3,600
number of contractor employees on site)`} /> </label>
                        <input
                            type="number"
                            className="form-control"
                            id="numberofContractors"
                            value={formData.numberofContractors}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="dailyHoursContractorInput" className="form-label">Total No of hours worked of contractor employee * <InfoIconWithTooltip id="info-dailyHoursContractorInput" data={`It takes TOTAL NUMBER of hours worked for the month.
(e.g., contractor employees per day per month x hours worked
per day x number of working days for the month)
(e.g., 120 employees x 12 hours x 30 working days = 43,200
hours worked for the month.
Note: If the contractor employees are having different working hours (e.g.
temporary or ad-hoc sub-contractors, etc), take average number of
working hours per day into the calculation.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="dailyHoursOfContractors"
                            value={formData.dailyHoursOfContractors}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="workingDaysOfContractors" className="form-label">Number of working days for Contractors * <InfoIconWithTooltip id="info-workingDaysOfContractors" data={`It takes number of working days for Contractor Employees for
that month. (e.g., 30 days).
Note: If the contractor employees are having different working days (e.g.
temporary or ad-hoc sub-contractors, etc), take average number of
working days in a month.`} /> </label>
                        <input
                            type="number"
                            className="form-control"
                            id="workingDaysOfContractors"
                            value={formData.workingDaysOfContractors}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="noOfSafety" className="form-label">No. of Safety Inductions Conducted * <InfoIconWithTooltip id="info-noOfSafety" data={`1) A safety induction is conducted to welcome new
employees/visitors/contractors to the company and prepare
them for their new role.

2) It includes safety induction videos and/or onboarding
session.
3) To be collected based on the number of people, not on the
number of sessions.
4) To be collected by the security or other person-in-charge
whenever the safety induction video is shown to people.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="noOfSafety"
                            value={formData.noOfSafety}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="noOfToolbox" className="form-label">No. of Toolbox Meetings/Safety Briefings/Safe Starts <InfoIconWithTooltip id="info-noOfToolbox" data={`1) It is a short and routine consultation designed to create
awareness of safety measures and hazards at the workplace,
before the commencement of the work.
2) To be collected via work permits issued, toolbox meeting
records.
3) Person-in-charge to record/receive/collect daily toolbox
meeting photos from the contractors and add up the total
number of toolbox meetings conducted for the month.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="noOfToolbox"
                            value={formData.noOfToolbox}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="noOfEhsTraining" className="form-label">No. of EHS Trainings * <InfoIconWithTooltip id="info-noOfEhsTraining" data={`1) Safety training refers to learning programs designed to train
employees on precautionary processes and procedures to
mitigate risk or the chance of injury or fatality on the job.
2) It includes internal and external training.
3) To be recorded as a session, not number of person attended.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="noOfEhsTraining"
                            value={formData.noOfEhsTraining}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="noOfInspection" className="form-label">No. of EHS Inspections/Audits * <InfoIconWithTooltip id="info-noOfInspection" data={`1) It is an on-site walk through to identify potential hazards
and options for remedial action.
2) It is based on the building, not location.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="noOfInspection"
                            value={formData.noOfInspection}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="noOfManagmentSiteWalk" className="form-label">No. of Management Site Walk/Inspection * <InfoIconWithTooltip id="info-noOfManagmentSiteWalk" data={`Similar to EHS inspection/audits. The only difference is the
inspection/walk is participated by the management beyond the
projects/DC Operations (e.g., management personnel from
regional/head office). The purpose could be beyond the scope
of EHS.`} /></label>
                        <input
                            type="number"
                            className="form-control"
                            id="noOfManagmentSiteWalk"
                            value={formData.noOfManagmentSiteWalk}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="noOfAuthority" className="form-label">No. of Authority/NGO/Union Visits * <InfoIconWithTooltip id="info-noOfAuthority" data={`Any visit by authority, NGO and Union for any purposes.`} /> </label>
                        <input
                            type="number"
                            className="form-control"
                            id="noOfAuthority"
                            value={formData.noOfAuthority}
                            onChange={handleInputChange}
                            onWheel={handleWheel}
                        />
                    </div>



                </form>
                }

            </Box>
        </>
    )
}

export default ReportModal;
